import { BrowserRouter } from 'react-router-dom'
import { SessionProvider } from './contexts/SessionContext'
import { Routes  } from './Routes'

function App() {
  return (
    <BrowserRouter>
      <SessionProvider>
        <Routes />
      </SessionProvider>
    </BrowserRouter>
  )
}

export default App;
