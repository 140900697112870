/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axiosInstance from "../utils/axios";

export const SessionContext = createContext({});

export function SessionProvider(props) {
  const { REACT_APP_STORAGE_ID: STORAGE_ID } = process.env
  const LS_IS_LOGGED = `${STORAGE_ID}isLogged`
  const LS_TOKEN = `${STORAGE_ID}token`
  const LS_ROUTE = `${STORAGE_ID}route`

  const _isLogged = JSON.parse(window.localStorage.getItem(LS_IS_LOGGED)) || false
  const _token = window.localStorage.getItem(LS_TOKEN) || ''
  const _route = window.localStorage.getItem(LS_ROUTE) || '/'

  const [user, setUser] = useState('')
  const [isLogged, setIsLogged] = useState(_isLogged)
  const [token, setToken] = useState(_token)
  const [route, setRoute] = useState(_route)

  const history = useHistory()

  useEffect(() => {
    window.localStorage.setItem(LS_IS_LOGGED, isLogged)
  }, [isLogged])

  useEffect(() => {
    if (token) window.localStorage.setItem(LS_TOKEN, token)
  }, [token])

  useEffect(() => {
    if (route) window.localStorage.setItem(LS_ROUTE, route)
  }, [route])
  
  async function sessionIsValid() {
    try {
      const result = await axiosInstance.get('/sessions', {
        headers: {
          "Authorization": `Bearer ${token}`
        },
      })
      if (result && result.status === 200) {
        return true
      }
    }
    catch {}
    return false
  }

  async function autoVerifySession(route) {

    async function _autoVerifySession(route) {
      if (!(await sessionIsValid())) {
        if (route) setRoute(route)
        setToken('')
        setIsLogged(false)
        return false
      }
      return true
    }

    if (await _autoVerifySession(route)) {
     setTimeout(() => autoVerifySession(route), 10000)
    }
  }

  function logout(noconfirm = false) {
    if (noconfirm || confirm('Quer mesmo encerrar a sessão?')) {
      history.push('/logout')
    }
  }
  
  return (
    <SessionContext.Provider value={{
      user,
      token,
      isLogged,
      route,
      setUser,
      setToken,
      setRoute,
      setIsLogged,
      sessionIsValid,
      autoVerifySession,
      logout,
    }}>
      {props.children}
    </SessionContext.Provider>
  )
}