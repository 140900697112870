/*type MyProgressBarProps = {
  now: number
  label: string
}*/

import '../styles/progressbar.scss'

export function MyProgressBar(props/*: MyProgressBarProps*/) {
  return (
    <div className="my-progress-bar">
      <div
        className="progress"
        style={{width: props.now+'%'}}
      >
        <span className="now">{`${props.now}%`}</span>
      </div>
    </div>
  )
}