/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useSession } from '../hooks/useSession'
import axiosInstance from "../utils/axios"

import { MyProgressBar } from '../components/MyProgressBar'

import '../styles/upload.scss'

// let cancelTokenSource = axiosInstance.CancelToken.source()

export function Upload() {
  const { token, isLogged, setRoute, autoVerifySession, logout } = useSession()

  const [selectedFiles, setSelectedFiles] =  useState()
  const [progress, setProgress] = useState()
  const [isDone, setIsDone] = useState(false)

  const history = useHistory()

  useEffect(async () => {
    setRoute('/upload')

    if (!isLogged) {
      history.push('/login')
      return
    }

    await autoVerifySession('/upload')
  }, [])

  useEffect(() => {
    if (progress === 100 && isDone) {
      alert('Upload concluído com sucesso!')
    }
  }, [progress, isDone])

  /* const cancelHandler = _e => {
    cancelTokenSource.cancel('Upload cancelled')
  }*/

  const submitHandler = async e => {
    e.preventDefault()

    if (!selectedFiles) {
      alert('Nenhum arquivo selecionado')
      return
    }

    let formData = new FormData()

    formData.append("file", selectedFiles[0])

    let result = false
    try {
      result = await axiosInstance.post("/upload_file", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`
        },
        onUploadProgress: data => {
          setProgress(Math.round((100 * data.loaded) / data.total))
        },
        // cancelToken: cancelTokenSource.token,
      })
      if (result) {
        setIsDone(true)
      }
    }
    catch (error) {
      alert('Upload falhou.')
    }
  }

  function handleLogout(e) {
    e.preventDefault()
    logout()
  }

  return isLogged && (
    <div className="container">
      <form
        action="#"
        method="post"
        encType="multipart/form-data"
        onSubmit={submitHandler}
      >
        <input
          type="file"
          id="exampleFormControlFile1"
          name="file"
          onChange={e => {
            setSelectedFiles(e.target.files)
          }}
        />

        {progress && <MyProgressBar now={progress} label={`${progress}%`} />}

        <button
          variant="info"
          type="submit"
        >
          Upload
        </button>
        {/*<button
          type="button"
          onPress={cancelHandler}
        >
          Cancelar
        </button>*/}
      </form>
      <nav>
        <a href="/">Home</a>
        <a href={process.env.REACT_APP_PAINEL_URL} >Painel</a>
        <a href="" onClick={handleLogout}>Sair</a>
      </nav>
    </div>
  );
}