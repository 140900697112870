/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'

import { useSession } from '../hooks/useSession'

import '../styles/home.scss'

export function Home() {
  const { isLogged, setRoute, logout } = useSession()

  useEffect(() => {
    setRoute('/')
  }, [])

  function handleLogout(e) {
    e.preventDefault()
    logout()
  }

  return (
    <div className="container">
      <h1>Admin Upload</h1>
      <nav>
        <a href="/upload">Upload</a>

        {!isLogged && (
          <a href="/login">Entrar</a>
        )}

        {isLogged && (
          <>
            <a href={process.env.REACT_APP_PAINEL_URL}>Painel</a>
            <a href="" onClick={handleLogout}>Sair</a>
          </>
        )}
      </nav>
    </div>
  )
}