import { Switch, Route } from 'react-router-dom'

import { Home } from './pages/Home'
import { Login } from './pages/Login'
import { Upload } from './pages/Upload'
import { Logout } from './pages/Logout'

export function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/login" component={Login} />
      <Route path="/upload" component={Upload} />
      <Route path="/logout" component={Logout} />
    </Switch>
  )
}