/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axiosInstance from '../utils/axios'

import { useSession } from '../hooks/useSession'

import '../styles/login.scss'

export function Login() {
  const { route, setToken, setUser, setIsLogged } = useSession()

  const history = useHistory()

  function formFocus() {
    if (window.user.value.trim()==='') {
      window.user.focus()
      window.user.select()
    }
    else {
      window.password.focus()
      window.password.select()
    }
  }

  useEffect(() => {
    formFocus()
  }, [])

  async function handleSubmit(e) {
    e.preventDefault()

    const user = e.target.user.value
    const password = e.target.password.value
    const formData = { user, password }

    let result
    try {
      result = await axiosInstance.post('/sessions', formData)
    }
    catch (error) {
      alert("Acesso negado!")
      formFocus()
    }

    if (result && result.data) {
      const token = result.data 
      setToken(token)
      setUser(user)
      setIsLogged(true)
      history.push(route)
    }
  }

  return (
    <div className="container">
      <h1>Acesso</h1>
      <form
        method="post"
        onSubmit={handleSubmit}
        >
          <input
            type="text"
            name="user"
            id="user"
            placeholder="Usuário"
            required
            />
      

          <input
            type="password"
            name="password"
            id="password"
            placeholder="Senha"
            required
            />
        <button variant="primary" type="submit">
          Entrar
        </button>
      </form>

      <a href="/">Home</a>
    </div>
  )
}