/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { useHistory } from 'react-router-dom'
import { useSession } from '../hooks/useSession'

export function Logout() {
  const history = useHistory()
  const { setIsLogged } = useSession()

  setIsLogged(false)
  history.push('/')

  return null
}